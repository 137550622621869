

<template>
  <section class="libraryBook-form-section">
<v-container>
  <!-- Library Name -->
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title class="headline">{{ library.name }}</v-card-title>
      </v-card>
    </v-col>
  </v-row>

  <!-- Search and Add/Edit Form -->
  <v-form v-model="valid" @submit.prevent="submitForm" ref="form" class="libraryBook-form">
    <!-- Search Text Field -->
    <v-row>
      <v-col>
    <v-menu
      v-model="menuVisible"
      :close-on-content-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="keyword"
          label="Titre"
          outlined
          @input="searchBooks"
          :rules="[v => !!v || 'Titre est requis']"
          :readonly="readonlytest"
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>

      <!-- Dropdown for Search Results -->
      <v-list v-if="searchResults.length">
        <v-list-item-group>
          <v-list-item
            v-for="book in searchResults"
            :key="book.bookId"
            @click="selectBook(book)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ book.titre }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-col>
  <v-col><v-text-field v-model="book.nbrPage" label="Nombre de pages" type="number" outlined required  :rules="[v => !!v || 'Nombre de pages est requis']":readonly="readonlytest"></v-text-field></v-col>
  </v-row>
    <!-- Form Fields for Adding/Editing Book -->
    
    <v-row>
      <v-col>
    <VueEditor
      v-model="book.description"
      auto-grow
      outlined
      label="Description"
      placeholder="Description"
      :readonly="readonlytest"
    ></VueEditor>

  </v-col>
  </v-row>
    <v-row>
    <v-col>
      <v-text-field 
      v-model="book.price" 
      @input="book.price = ModificationPrice(book.price)"
      label="Prix" outlined required  
      :rules="[v => !!v || 'Prix est requis']" >
    </v-text-field></v-col>
    <v-col><v-text-field v-model="book.quantity" label="Quantité" outlined required  :rules="[v => !!v || 'Quantité est requis']"></v-text-field></v-col>
    <v-col>
    <v-autocomplete
      v-model="book.levelId"
      :items="levels"
      item-value="id"
      item-text="name"
      label="Niveau"
      outlined
      required
       :rules="[v => !!v || 'Niveau est requis']"
       :readonly="readonlytest"
    ></v-autocomplete>
  </v-col>
  </v-row>
  <v-row>
    
  <v-col>
    <v-autocomplete
      v-model="book.categoryId"
      :items="categories"
      item-value="id"
      item-text="name"
      label="Catégorie"
      outlined
      required
       :rules="[v => !!v || 'Catégorie est requis']"
       :readonly="readonlytest"
    ></v-autocomplete>
  </v-col>
  <v-col>
    <v-autocomplete
      v-model="book.authorId"
      :items="authors"
      item-value="id"
      item-text="name"
      label="Auteur"
      outlined
      required
       :rules="[v => !!v || 'Auteur est requis']"
       :readonly="readonlytest"
    ></v-autocomplete>
    </v-col>

    <!-- Input for Searching Publish Houses -->
    <v-col>
      <!--
    <v-menu
      v-model="menuVisible"
      :close-on-content-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="key"
          label="Maison d'édition"
          outlined
          @input="searchHouses"
          :rules="[v => !!v || 'Maison d\'édition est requis']"
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>

       Dropdown for Search Results 
      <v-list v-if="searchResult.length">
        <v-list-item-group>
          <v-list-item
            v-for="publishHouse in searchResult"
            :key="publishHouse.id"
            @click="selectPublishHouse(publishHouse)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ publishHouse.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    -->
    <v-autocomplete
    v-model="book.publishHouseId"
    :items="publishHouses"
    item-value="id"  
    item-text="name"
    label="Maison d'édition"
    outlined
    required
    :rules="[v => !!v || 'Maison d\'édition est requis']"
    :readonly="readonlytest"
  ></v-autocomplete>
  </v-col>

  </v-row>
  <v-row v-if="keyword && books.length > 0">
    

    <v-col cols="12" sm="6" md="6">
    <!-- VueUploadMultipleImage component for handling images -->
    <vue-upload-multiple-image
      v-model="images"
      :data-images="images"
      name="images"
      drag-text="Please add an image!"
      browse-text="Browse image"
      primary-text="Primary image"
      mark-is-primary-text="Set default image"
      :readonly="readonlytest"
      :min-image="1"
      :max-image="3"
      :multiple="true"
      :loading="load"
      :showEdit="false"
      :showDelete="!readonlytest"
      :showAdd="!readonlytest"
      popup-text="Description default image"
      drop-text="Drag and drop"
      @upload-success="uploadImageSuccessS" 
      @before-remove="beforeRemove"
      ></vue-upload-multiple-image>
</v-col>
  </v-row>
    <v-row v-if="uploadInProgress">
  <v-col cols="12">
    <v-progress-linear
      indeterminate
      color="primary"
      height="5"
    ></v-progress-linear>
  </v-col>
</v-row>
    <!-- Submit Button -->
    <v-btn type="submit" color="info" :loading="loading" :disabled="!isFormComplete">
      {{ isEdit ? 'Modifier' : 'Ajouter' }} livre
    </v-btn>
  </v-form>
  <v-divider class="my-4"></v-divider>
  <!-- Book Table -->
  <v-card >
        <v-card-title>Liste des Livres</v-card-title>
      <v-text-field
        style="padding:15px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Recherche"
        single-line
        hide-details
        clearable
        :search="search"
        
      ></v-text-field>
  <v-data-table
    :headers="bookHeaders"
    :items="books"
    item-key="bookId"
    :search="search"
    :loading="loading"
    :no-data-text="noDataText"
    :items-per-page="itemsPerPage"

    hide-default-footer

  >
    <!-- Display Image in Table -->
    <!-- <template v-slot:item.listFileUrl="{ item }">
      <v-img v-if="item.listFileUrl && item.listFileUrl.length > 0" :src="item.listFileUrl[0]" width="100px" height="100px"style="border-radius: 10px;margin:10px;box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);"></v-img>
    </template> -->
  
    <template v-slot:item.listFileUrl="{ item }">

<v-img 
  style="margin: 10px"
  

  :src="item.listFileDBId.length!=0 ? getImageUrl(item.listFileDBId) : item.listFileUrl[0]"
  width="65"
  height="65"
/>
<v-divider></v-divider>
</template>
// 

    <!-- Actions Column in Table -->
    
    <template v-slot:item.edit="{ item }">
      <v-icon  @click="editBook(item)" class="edit-icon">mdi-pencil</v-icon>
    </template>
    <template v-slot:item.delete="{ item }">
      <v-icon  @click="deleteBook(item.id, item.publishHouseId)" class="delete-icon">mdi-delete</v-icon>
    </template>
  </v-data-table>
  <!-- Pagination component -->
    <div class="pagination-container">
      <v-pagination
      v-model="currentPage"
        :length="totalPages"
        :total-visible="7"
        @input="handlePageChange"
      ></v-pagination>
    </div>
  </v-card>

  <!-- Snackbar for Notifications -->
  <v-snackbar v-model="snackbar.show" :color="snackbar.color" top>
    {{ snackbar.message }}
    <v-btn color="white" text @click="snackbar.show = false">Fermer</v-btn>
  </v-snackbar>
 <!-- Confirmation Dialog -->
 <v-row justify="center">
      <v-dialog v-model="dialog.show" max-width="530">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir supprimer le livre ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
              @click="dialog.show = false"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text @click="confirmDelete">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</v-container>
</section>
</template>

<script>
import axios from 'axios';
import store from '../store/image';
import Constant from "@/utils/constants";
import VueUploadMultipleImage from 'vue-upload-multiple-image';
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
import { component as VueCodeHighlight } from "vue-code-highlight";
import { isReadonly } from 'vue';

export default {
components: {
  VueUploadMultipleImage,VueEditor,VueCodeHighlight
},
//props: ['id'],
computed: {
   isReadOnly() {
    const ownerId = localStorage.getItem('ownerId'); 
    const ownerIdValue = parseInt(ownerId);    
    if(this.book.createdBy != null)
    {
      
      return this.isEdit && this.book.createdBy != ownerIdValue;
    }
    else{
      
      return true;
    }
    
  },
  isFormComplete() {
  //   return (
  //     (this.book.titre || '').trim() &&
  //     (this.book.description || '').trim()  &&
  //     (String(this.book.price) || '').trim()  &&
  //     this.book.quantity && this.book.quantity > 0 &&
  //     this.book.nbrPage > 0 &&
  //     this.book.levelId !== null &&
  //     this.book.categoryId !== null &&
  //     this.book.authorId !== null &&
  //     this.book.publishHouseId !== null 
  //     // this.isImgUploaded
  //   );
  // },
  const baseValidation =
      (this.keyword || '').trim()  &&
      (this.book.description || '').trim()  &&
      (String(this.book.price) || '').trim()  &&
      this.book.quantity > 0 &&
      this.book.levelId !== null &&
      this.book.nbrPage > 0 &&
      this.book.categoryId !== null &&
      this.book.authorId !== null &&
      this.book.publishHouseId !== null
       // Si on est en mode "ajouter", vérifier que l'image a été téléchargée
  // if (this.images.length === 0) {
  //   return baseValidation && this.isImgUploaded;
  // }
  // Si on est en mode "modifier" et que l'image est déjà présente ou non vide, pas besoin de valider l'image
  return baseValidation;
  },
},
data() {
  return {
    currentPage: 1,
    itemsPerPage: 100, // Items per page
    totalPages: 0,
    uploadInProgress: false, 
    isImgUploaded : false,
    ownerId:localStorage.getItem('ownerId'),
    dialog: {
      show: false,
      bookId: null,
      publishHouseId:null,
    },
    //isOwner: false, 
    library: {},
    books: [],
    searchResults: [],
    searchResult: [],
    menuVisible: false,
   isEdit: false,
    book: {
      libraryId: this.id,
      bookId: null,
      titre: '',
      description: '',
      // nbrPage: 0,
      price: '',
      quantity: '',
      levelId: null,
      categoryId: null,
      authorId: null,
      publishHouseId:null,
       listFileUrl: [],
      listFileId: [],
      listFileDBId: [],
      createdBy: localStorage.getItem('ownerId')
    },
    keyword: '',
    key:'',
    search: '',
    noDataText: 'No books found',
    images: [],
    load: false,
    valid: false,
    loading: false,
    readonlytest:false,
    bookHeaders: [
      { text: 'Image', value: 'listFileUrl' },
      { text: 'Titre', value: 'titre' },
      { text: 'Maison édition', value: 'publishHouseName' },
      { text: 'Prix', value: 'price' },
      { text: 'Quantité', value: 'quantity' },
      { text: 'Modifier', value: 'edit', sortable: false },
      { text: 'Supprimer', value: 'delete', sortable: false },
    ],
    levels: [],
    categories: [],
    authors: [],
    publishHouses:[],
    publishHouseInput: '',
    publishHouseOptions: [], 
    snackbar: { show: false, message: '', color: '' },
  };
},


async created() {
  await this.fetchLibraryDetails();
  await this.fetchBooks();
  await this.fetchLevels();
  await this.fetchCategories();
  await this.fetchAuthors();
  await this.fetchPublishHouse();
},
mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  
methods: {
  getImageUrl(listFileDBId) {
  if (listFileDBId !== null) {
    return `${Constant.QORANI}fileDB/files/${listFileDBId[0]}`;
  } else {
    return null;
  }
},
    getImageUrl2(listFileDBId) {
      return `${Constant.QORANI}fileDB/files/${listFileDBId}`;
    },
  /*********************  fetchLibraryDetails  *******************************/
  async fetchLibraryDetails() {
    try {
       const userIdtest =  localStorage.getItem('ownerId')
      const response = await axios.get(Constant.QORANI+`library/library/getByOwnerId/${userIdtest}`);
      this.library = response.data;
     
    } catch (error) {
      this.handleError('Failed to fetch library details', error);
    }
  },
    /*********************  afficher livres  *******************************/

    async fetchBooks() {
    //this.loading = true;
    try {
      
        const response = await axios.post(Constant.QORANI + `library/book/getallByLibraryId`, {
            pageNumber: this.currentPage - 1,
            pageSize: this.itemsPerPage,
            fieldId: this.library.id,
        });

        this.books = response.data.bookResponseList;
        this.totalPages = response.data.totalPageNumber;
    } catch (error) {
        this.handleError('Failed to fetch books', error);
    } 
},

handlePageChange(page) {
    this.currentPage = page;
    this.fetchBooks(); // Fetch the new page of books
},
      /*********************  afficher Niveaux  *******************************/

  async fetchLevels() {
    try {
      const response = await axios.get(Constant.QORANI+`library/level/getall`);
      this.levels = response.data;
    } catch (error) {
      this.handleError('Failed to fetch levels', error);
    }
  },
        /*********************  afficher categories  *******************************/

  async fetchCategories() {
    try {
      const response = await axios.get(Constant.QORANI+`library/category/getall`);
      this.categories = response.data;
    } catch (error) {
      this.handleError('Failed to fetch categories', error);
    }
  },
        /*********************  afficher auteurs  *******************************/

  async fetchAuthors() {
    try {
      const response = await axios.get(Constant.QORANI+`library/author/getall`);
      this.authors = response.data;
    } catch (error) {
      this.handleError('Failed to fetch authors', error);
    }
  },
  async fetchPublishHouse() {
    try {
      const response = await axios.get(Constant.QORANI+`library/publishHouse/getall`);
      this.publishHouses = response.data;
    } catch (error) {
      this.handleError('Failed to fetch publish houses', error);
    }
  },

  ModificationPrice(value) {
    //type of value
    if (typeof value !== 'string') {
      //converts value to a string
      value = String(value);
    }
    const normalizedValue = value.replace(',', '.');
    return parseFloat(normalizedValue);
  },
  async submitForm() {
this.$refs.form.validate();

if (!this.valid ) {
  return;
}
if (!this.book.publishHouseId) {
    this.showSnackbar("SVP ajouter une maison d'édition déja exist", "error");
    return;
  }
try {
  this.loading = true;
 this.book.libraryId = this.library.id;
  this.book.nbrPage = parseInt(this.book.nbrPage, 10);
  // Normalize price input
  this.book.price = this.ModificationPrice(this.book.price);
  this.book.quantity = parseInt(this.book.quantity, 10);
  //this.book.publishHouseId = this.publishHouses.find(house => house.name === this.key)?.id || null;
  const ownerId = localStorage.getItem('ownerId');

  let response;
  
  if (this.book.id) {
    // edit == true : modification
    if(this.isEdit){
    
      const ownerIdValue = parseInt(ownerId);
      const imageIds = store.state.imageIds;


// this.book.listFileDBId = [imageId];

// this.book.listFileDBId = imageIds
      if (this.book.createdBy === ownerIdValue) {
       response = await axios.post(Constant.QORANI + `library/book/edit/${this.book.id}`, {
        titre: this.keyword,
        description: this.book.description,
        nbrPage: this.book.nbrPage,
        levelId: this.book.levelId,
        categoryId: this.book.categoryId,
        authorId: this.book.authorId,
        listFileDBId : imageIds


        });
        console.log( "44444444444",  this.keyword,
         this.book.description,
      this.book.nbrPage,
        this.book.levelId,
         this.book.categoryId,
         this.book.authorId,this.book.listFileDBId)
       } 

      // Updating existing book
      response = await axios.post(Constant.QORANI + `library/library/editBookLibrary`, {

      libraryId: this.library.id,
      bookId: this.book.id,
      publishHouseId: this.book.publishHouseId,
      price: this.book.price,
      quantity: this.book.quantity,
    });
    console.log( "5555555555555",  this.library.id,
       this.book.id,
       this.book.publishHouseId,
      this.book.price,
       this.book.quantity,
      )


    if(response.data.success){
      this.showSnackbar('Livre mis à jour avec succès', 'success');
      console.log("responde",response);
      console.log(
       this.keyword,
        this.book.description,
         this.book.nbrPage,
         this.book.levelId,
         this.book.categoryId,
         this.book.authorId,this.book.listFileDBId)
    }else{
      this.showSnackbar('Echec Update', 'Echec');
    }
    }
    else {
  const imageIds = store.state.imageIds; // Récupérer l'image ID depuis le store

this.book.listFileDBId =imageIds;



  // Ajout d'un livre existant à la bibliothèque
  response = await axios.post(Constant.QORANI + `library/library/addBookToLibrary`, {
    libraryId: this.library.id,
    bookId: this.book.id,
    publishHouseId: this.book.publishHouseId,
    price: this.book.price,
    quantity: this.book.quantity,

  });

  console.log("2222222222", response);
  
  if (response.data.success) {
    this.showSnackbar('Livre ajouté avec succès', 'success');
  } else {
    this.showSnackbar('Échec de l\'ajout', 'error');
  }
}

  }
  else {
    const imageIds = store.state.imageIds;


// this.book.listFileDBId = [imageId];
    this.book.listFileDBId = imageIds.slice(0, 3);
    // Adding new book
    this.book.titre = this.keyword;

    // if(this.isImgUploaded){
console.log(this.book)

      response = await axios.post(Constant.QORANI + `library/book/add`, this.book);
      this.book.id = response.data.id;
      

      response=await axios.post(Constant.QORANI + `library/library/addBookToLibrary`, {
        libraryId: this.library.id,
        bookId: this.book.id,
        publishHouseId: this.book.publishHouseId,
        price: this.book.price,
        quantity: this.book.quantity,
      });
      console .log ("33333333333333333", this.library.id,
         this.book.id,
        this.book.publishHouseId,
         this.book.price,
      this.book.quantity,

this.book.listFileDBId );

      this.showSnackbar('Livre ajouté avec succès', 'success');
      window.location.reload();

    // }
    
  }
  this.resetForm();
  this.$refs.form.reset();
  await this.fetchBooks(); 
} catch (error) {
  this.handleError('Submission failed', error);
} finally {
  this.loading = false; 
}
},
editBook(book) {
  event.preventDefault();
  this.book = { ...book }; 
  this.isEdit = true;
  
  // Initialiser le tableau d'images
  if(book.listFileDBId.length != 0){
    this.images = book.listFileDBId.map(id => ({
    name: "",
    path: this.getImageUrl2(id), // Utiliser la méthode pour obtenir l'URL
    highlight: 1,
    default: 1,
  }));
  }else{
    this.images = book.listFileUrl.map(url => ({
    name: "",
    path: url, // Utiliser la méthode pour obtenir l'URL
    highlight: 1,
    default: 1,
  }));
  }
  
  
  // Si vous avez besoin de garder une image par défaut, vous pouvez le faire ici
  // this.images[0].default = 1; // Exemple, si vous souhaitez mettre la première image comme par défaut

  this.keyword = book.titre || ''; 
  this.key = this.publishHouses.find(ph => ph.id === book.publishHouseId)?.name || '';

  const ownerId = localStorage.getItem('ownerId'); 
  const ownerIdValue = parseInt(ownerId, 10);
  this.readonlytest = this.isEdit && this.book.createdBy !== ownerIdValue;
  
  // Scroll vers le formulaire
  this.$nextTick(() => {
    const formElement = this.$refs.form.$el; 
    formElement.scrollIntoView({ behavior: 'smooth' }); 
  });
},
deleteBook(id, publishHouseId) {
      this.dialog.bookId = id;
      this.dialog.publishHouseId = publishHouseId; 
      this.dialog.show = true;
},
async confirmDelete() {
    try {
      await axios.post(Constant.QORANI + `library/library/removeBookFromLibrary`, {
        libraryId: this.library.id,
        bookId: this.dialog.bookId,
        publishHouseId: this.dialog.publishHouseId, 
        
      });
      
      this.showSnackbar('Livre supprimé avec succès', 'success');
      await this.fetchBooks();
      
    } catch (error) {
      this.handleError('Error deleting book', error);
    } finally {
      this.dialog.show = false; // Close the dialog
    }
  },
  async searchBooks() {
    if (this.keyword.length < 3) {
      this.searchResults = [];
      return;
    }
    try {
      this.loading = true;
      const response = await axios.get(Constant.QORANI+`library/book/findByKeyword/${this.keyword}`);
      this.searchResults = response.data;
    } catch (error) {
      this.handleError('Failed to search books', error);
    } finally {
      this.loading = false;
    }
  },
  
  selectBook(book) {
        if (book) {
          this.book = { ...book, price: '', quantity: '' };
          this.keyword = book.titre || ''; 
          this.searchResults = [];
          
        } else {
          
        }
        this.menuVisible = false; 
},

  async searchHouses() {
    if (this.key.length < 3) {
      this.searchResult = [];
      return;
    }
    try {
      this.loading = true;
      const response = await axios.get(Constant.QORANI+`library/publishHouse/getByKeyword/${this.key}`);
      this.searchResult = response.data;
    } catch (error) {
      this.handleError('Failed to search books', error);
    } finally {
      this.loading = false;
    }
  },
  selectPublishHouse(publishHouse) {
  if (publishHouse) {
    this.book.publishHouseId = publishHouse.id;
    this.key = publishHouse.name || '';
    this.searchResult = [];
     this.bookId = null; 
  } else {
    
  }
  this.menuVisible = false; 
},

  resetForm() {
    this.book = {
      libraryId: this.id,
      bookId: null,
      titre: '',
      description: '',
      nbrPage: 0,
      price: '',
      quantity: '',
      levelId: null,
      categoryId: null,
      authorId: null,
      publishHouseId:null,
      listFileUrl: [],
      listFileDBId: [],
      createdBy: localStorage.getItem('ownerId')
    };
    this.images = [];
    this.keyword = ''; 
    this.searchResults = []; 
    this.isEdit = false;
  },
  handleError(message, error) {
    this.showSnackbar(`${message}: ${error.message}`, 'error');
  },
  showSnackbar(message, color) {
    this.snackbar.message = message;
    this.snackbar.color = color;
    this.snackbar.show = true;
  },
  
  async uploadImageSuccessS(formData, index, fileList) {
      try {
        await store.dispatch("uploadImageSuccessS", { formData, index, fileList });
        const imageIds = store.state.imageIds;
        console.log("Images ids :", imageIds);
      } catch (error) {
        console.error("Failed to upload image:", error);
      }
    },

  
    beforeRemove(index, done, fileList) {
  console.log('Avant suppression :', index, fileList);
  
  const confirmation = confirm("Voulez-vous vraiment supprimer cette image ?");
  
  if (confirmation) {
    console.log('Suppression confirmée pour l\'index :', index);
    this.images = [];
    this.book.listFileDBId = [];
    this.book.listFileUrl = [];
    this.$emit('input', this.images);
    done(); 
    console.log( "ee",this.book.listFileDBId ,
    this.book.listFileUrl )
  } 
},


  
},
};
</script>

<style>


.v-icon {
cursor: pointer;
transition: color 0.3s, transform 0.3s;
}

.edit-icon:hover {
color: #1976d2;
transform: scale(1.2);
}

.delete-icon:hover {
color: #d32f2f;
transform: scale(1.2);
}
.libraryBook-form-section {
padding: 24px;
background-color: #f4f7f9;
border-radius: 12px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.libraryBook-form {
padding: 20px;
background-color: white;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.q-editor {

margin-bottom: 24px; /* Space below editor */
border-radius: 8px; /* Rounded corners */
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow */
background-color: #f9f9f9; /* Light background color */
}
.v-divider {
margin: 20px 0;
}
</style>
